import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {Navbar} from './components/navbar';
import {Home} from './pages/home/home';
import {AboutMe} from './pages/aboutme/aboutme';
import {CurrentProjects} from './pages/currentprojects/currentprojects';
import {FindMe} from './pages/findme/findme';
import {Footer} from './components/footer'

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/currentprojects" element={<CurrentProjects />} />    
          <Route path="/findme" element={<FindMe />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
