import './home.css'
import '../../App.css'
//import { useNavigate } from 'react-router-dom';
//import Elementary from '../../images/elementary.png'
import RyanImage from '../../images/ryan.png'
import Wyoming from '../../images/wyoming.png'
import {SkillsGrid} from '../../components/skillsgrid'

export const Home = () => {
    
    const mySkills = [
        { name: 'HTML' },
        { name: 'CSS' },
        { name: 'JavaScript' },
        { name: 'React' },
        { name: 'Firebase' },
        { name: 'Figma' },
      ];

    return(
        <div>
            
        <div className="home-container">
        <br />
        <div className="container">
            <div className="animation-wrapper">
            <div className="typed-out"></div>
            </div>
            <br></br>
        </div>
        
        <div className="content-grid">
            <div className="image-container">
                <img src={RyanImage} alt="kaytra" className="homeimage"/>
                <br></br><br></br>
            </div>
            <div className="text-container">
                <h1 className="study-classes"> <span className="all-in">&lt;ryan thieu&gt;</span></h1>
                <h2>developer with experience in html, css, js, react, python, java, and ui design.</h2>
                
            </div>
        </div>
        <div className="content-grid">
            <div className="text-container centered">
                <h3><a href="/curriculum" className="underlinehome">programming</a></h3>
            </div>
        </div>
        <br /><br />
        <hr />
        <br />
        <div>

            <h1 className="mb-4 text-4xl font-inter leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white"><span style={{ color: 'rgb(48, 33, 205)' }}>skills</span></h1>
            

    
        </div>

        <div className="skill-item-color">
            <SkillsGrid skills={mySkills} title="My Technical Skills" />
        </div>

        <div>
            <div>
                <div className="imagecontainer2">
                    <img src={Wyoming} alt="extraimage" />
                </div>
                <h3 className="image-text">i like to travel as well</h3>
            </div>
            <br />
            <br />
            <hr className="homehorizontal"></hr>
            
        </div>
       
    </div>
        
        
    
    </div>
    )
}
