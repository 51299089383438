import {Link} from 'react-router-dom';
import '../App.css';
import LogoImage from '../images/ace.png'
import './navbar.css'

export const Navbar = () =>{
    return(
       <div>
        <nav className="ok">
            <div className="logo-container">
                <Link to="/" className="flex-shrink-0">
                <img src={LogoImage} className="logo" alt="ACE Logo" />
                </Link>
            </div>
            <div className="links-container">
                <Link to="/" className="nav-link">home</Link>
                <Link to="/aboutme" className="nav-link">about me</Link>
                <Link to="/currentprojects" className="nav-link">current projects</Link>
                <Link to="/findme" className="nav-link">find me</Link>
            </div>
        </nav>
        <hr className="hrcolor"></hr>
        </div>
      
    );
}