import './currentprojects.css'

export const CurrentProjects = () =>{
    return(
        <div className="currentprojectspage">
            <h1 className="heading">current projects</h1>
            <h2 className="currentheading">ui/ux designer @ aurality</h2>
            <p className="currentdescription">creating designs for a new streaming service to upload your own music</p>
            <h2 className="currentheading">chief technology officer @ ACE </h2>
            <p className="currentdescription">creating an all-in-one website for students to learn. includes login portal, posts feature, and information.</p>
        </div>
    );
}