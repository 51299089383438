import './footer.css'
import LogoImage from '../images/ace.png'

export const Footer = () => (
    <div className="content">
        <script src="https://kit.fontawesome.com/3150cf19f5.js" crossOrigin="anonymous"></script>
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          <div className="footer__section footer__section--main">
            <img src={LogoImage} alt="ACE Logo" className="footer__logo"  />
          </div>
  
         
          <div className="footer__section">
            <h3 className="footer__heading">useful links</h3>
            <ul className="footer__list">
              <li><a href="/">home</a></li>
              <li><a href="/aboutme">about me</a></li>
              <li><a href="/currentprojects">current projects</a></li>
              <li><a href="/findme">find me</a></li>
            </ul>
          </div>
  
          
          <div className="footer__section">
            <h3 className="footer__heading">contact me</h3>
            <ul className="footer__list">
              <li><a href="/findme">contact me</a></li>
              <li><a href="mailto:ryan@rthieu.com" rel="noreferrer" target="_blank">ryan@rthieu.com</a></li>
            </ul>
          </div>
  
          
          <div className="footer__section">
            <h3 className="footer__heading">socials</h3>
            <ul className="footer__list">
              <li><a href="https://www.github.com/ryanthieu" target="_blank" rel="noreferrer">github</a></li>
              <li><a href="https://www.linkedin.com/in/ryanthieu/" target="_blank" rel="noreferrer">linkedin</a></li>
              <li><a href="mailto:ryan@rthieu.com" rel="noreferrer" target="_blank">email</a></li>
              <li><a href="https://www.instagram.com/rthieu_?igsh=MWhqeGE4NGhmZDRjaA%3D%3D&utm_source=qr" target="_blank" rel="noreferrer">instagram</a></li>
            </ul>
          </div>
        </div>
  
        {/* Copyright */}
        <div className="footer__copyright">
          <p>copyright &copy; {new Date().getFullYear()} Ryan Thieu - all rights reserved</p>
        </div>
      </div>
    </footer>
    </div>
  );

