import './aboutme.css'
export const AboutMe = () => {
    return(
        <div className="aboutmepage">
        <h1 className="aboutmeheading">about me</h1>
        <p className="aboutmepara">I am currently a junior at Plano East Senior High School in Plano, Texas.<br /><br />
            Currently, I am interested in anything related to computer science, ai, business, or technology. <br /></p>
        <h1 className="aboutmeheading">education</h1>
        <p className="aboutmepara">
            C.A. McMillen High School 2022-2024
        </p>
        <p className="aboutmepara">
            Plano East Senior High School 2024-2026
        </p>
        <h1 className="aboutmeheading">previous experience</h1>
        <h2 className="aboutmeinnerheading">web developer</h2>
        <p className="aboutmepara">making websites for personal and client use</p>
        <h2 className="aboutmeinnerheading">ui/ux designer @ yeezy</h2>
        <p className="aboutmepara">made 100+ designs for a comprehensive app; worked with a team of 14 to make designs that boosted engagement.</p>
        </div>
    );
}