import './skillsgrid.css'

interface Skill {
    name: string;
  }
  
  interface SkillsGridProps {
    skills: Skill[];
    title?: string;
  }


export const SkillsGrid = ({ skills, title = 'Skills' }: SkillsGridProps) => {
   
    return(
        <div>
            <div className="skills-container">
                <div className="skills-grid">
                    {skills.map((skill, index) => (
                    <div key={index} className="skill-item">
                    {skill.name}
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
}